<template>
  <v-card height="100%">
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-account-network</v-icon>
      Top Usuarios Networkings
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="reloadGet"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text v-if="top_networkings.length">
      <v-data-table
        :headers="headers"
        :items="top_networkings"
        :loading="loading"
        disable-sort
        hide-default-footer
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToNetworking"
      >
      </v-data-table>
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhuma networking Registrado
    </v-card-text>
  </v-card>
</template>

<script>
import { topNetworkings } from "@/api/admin/graficos/networking.js";

export default {
  name: "BoxTotalPosts",

  components: {},

  data() {
    return {
      top_networkings: [],
      loading: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Quantidade",
          value: "qtde",
          align: "right",
        },
      ];
    },
  },

  methods: {
    goToNetworking(item) {
      this.$router.push({ path: `/networkings/usuario/${item.user_id}` });
    },
    getTopNetworkings() {
      this.loading = true;
      topNetworkings()
        .then((response) => {
          this.top_networkings = response;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    reloadGet() {
      this.top_networkings = [];
      this.getTopNetworkings();
    },
  },

  mounted() {
    this.getTopNetworkings();
  },
};
</script>

<style></style>
